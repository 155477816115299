import React from 'react'

import styles from './QuotePage.module.scss'
import { FormContainer } from 'components/shared/Forms'
import Button from 'components/shared/Button'
import { ContactStep, DateStep, GeneralStep, LocationStep } from './steps'
import classyEvent from '../../../static/img/home/classy-event.jpeg'
import ImageHeader from 'components/shared/ImageHeader'
import { LegalAgreement } from 'components/Auth/SignUp'
import scrollToAnchor from 'utils/scrollToAnchor'
import ballons from '../../../static/img/quote/event.png'

export default class QuoteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSection: '',
      refMap: {},
      event_type: '',
      supplier_type: '',
      description: '',
      address1: '',
      address2: '',
      city: '',
      zipcode: '',
      num_guests: null,
      budget: null,
    }
  }

  updateCurrentSection = sectionID => {
    this.setState({ currentSection: sectionID })
  }

  updateRefMap = (sectionID, sectionRef) => {
    this.setState(state => ({
      refMap: {
        ...state.refMap,
        [sectionID]: sectionRef,
      },
    }))
  }

  onClickHandler = targetID => {
    const target = this.state.refMap[targetID] || false
    return scrollToAnchor(
      target,
      () => {
        this.updateCurrentSection(targetID)
      },
      150
    )()
  }

  onChange = event => {
    if (event.target.value == null) return
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <ImageHeader image={ballons} title="Request a Quote" />
        <div className={styles.welcome}>
          <p>
            <b>Hi there!</b> Tell us a bit about your event and we’ll connect
            you with the perfect suppliers!
          </p>
        </div>
        <FormContainer width="600px" style={{ marginTop: 0 }}>
          <form role="feedback-form" className={styles.container}>
            <GeneralStep
              initialData={{ ...this.state }}
              onChange={this.onChange}
              onClickHandler={this.onClickHandler}
              updateCurrentSection={this.updateCurrentSection}
              updateRefMap={this.updateRefMap}
            />
            <DateStep
              initialData={{ ...this.state }}
              onChange={this.onChange}
              onClickHandler={this.onClickHandler}
              updateCurrentSection={this.updateCurrentSection}
              updateRefMap={this.updateRefMap}
            />
            <LocationStep
              initialData={{ ...this.state }}
              onChange={this.onChange}
              onClickHandler={this.onClickHandler}
              updateCurrentSection={this.updateCurrentSection}
              updateRefMap={this.updateRefMap}
            />
            <ContactStep
              initialData={{ ...this.state }}
              onChange={this.onChange}
              onClickHandler={this.onClickHandler}
              updateCurrentSection={this.updateCurrentSection}
              updateRefMap={this.updateRefMap}
            />
            <LegalAgreement />
            <Button big>Get a Quote</Button>
          </form>
        </FormContainer>
      </div>
    )
  }
}
