export const EVENT_TYPES = [
  'Coporate Event',
  'Company Party',
  'Public-facing Event',
  'Wedding',
  'Birthday',
  'Dinner Party',
  'Celebration',
]

export const SUPPLIER_TYPE = ['Any', 'Independent Business']

export const TIME = [
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4:00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM',
  '12:00 AM',
]
