import React from 'react'

import QuotePage from 'components/QuotePage'
import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import { useNavbarConfig } from 'components/Navbar/NavbarConfig'

export default ({ location }) => {
  useNavbarConfig({ transparent: true, mode: 'stuck' })
  return (
    <Layout navbar="main" location={location}>
      <SEO title="Request a quote for event planning" />
      <QuotePage />
    </Layout>
  )
}
