import React from 'react'

import styles from './QuoteStep.module.scss'
import { FormInput, FormRow, FormCol } from 'components/shared/Forms'
import * as data from '../data'
import { CalendarStarIcon } from 'components/shared/Icons'
import IntersectionScroll from 'components/shared/IntersectionScroll'
import NextButton from './NextButton'

export default ({
  initialData,
  updateCurrentSection,
  updateRefMap,
  onChange,
  onClickHandler,
}) => {
  let {
    event_type,
    supplier_type,
    num_guests,
    description,
    budget,
  } = initialData

  return (
    <IntersectionScroll
      id="general"
      observeOnScroll
      updateCurrentSection={updateCurrentSection}
      updateRefMap={updateRefMap}
      className={styles.questionGroup}
    >
      <div className={styles.questionGroupHeader}>
        <CalendarStarIcon />
        <h3>Event Details</h3>
      </div>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="What type of event are you having?">
            <select
              name="event_type"
              required
              value={event_type}
              onChange={onChange}
            >
              <option value="" disabled="">
                {' '}
                Select event type
              </option>
              {data.EVENT_TYPES.map(type => {
                return (
                  <option value={type} key={type}>
                    {type}
                  </option>
                )
              })}
            </select>
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="What type of suppliers are you interested in?">
            <select
              name="supplier_type"
              required
              value={supplier_type}
              onChange={onChange}
            >
              <option value="" disabled="">
                {' '}
                Select supplier type
              </option>
              {data.SUPPLIER_TYPE.map(type => {
                return (
                  <option value={type} key={type}>
                    {type}
                  </option>
                )
              })}
            </select>
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="How many guests?">
            <input
              type="number"
              name="num_guests"
              min="0"
              value={num_guests}
              onChange={onChange}
            />
          </FormInput>
        </FormCol>
        <FormCol>
          <FormInput label="Expected budget">
            <input
              type="number"
              name="budget"
              min="0"
              value={budget}
              onChange={onChange}
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Describe the event">
            <textarea
              type="description"
              name="description"
              placeholder="Tell us a bit about the event. List supplies that you may need, tell us about the venue, or what accomadations will need to be made."
              required
              rows="5"
              value={description}
              onChange={onChange}
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <NextButton onClickHandler={() => onClickHandler('date')} />
    </IntersectionScroll>
  )
}
