import React, { useState } from 'react'

import styles from './QuoteStep.module.scss'
import { FormInput, FormRow, FormCol } from 'components/shared/Forms'
import { CalendarIcon } from 'components/shared/Icons'
import DatePicker from 'components/shared/DatePicker/DatePicker'
import * as data from '../data'
import IntersectionScroll from 'components/shared/IntersectionScroll'
import NextButton from './NextButton'

export default ({
  initialData,
  updateCurrentSection,
  updateRefMap,
  onChange,
  onClickHandler,
}) => {
  const { start_date, end_date, start_time, end_time } = initialData
  const [[startDate, endDate], setDates] = useState([null, null])
  const [focusedInput, setFocusedInput] = useState(null)

  return (
    <IntersectionScroll
      id="date"
      observeOnScroll
      updateCurrentSection={updateCurrentSection}
      updateRefMap={updateRefMap}
      className={styles.questionGroup}
    >
      <div className={styles.questionGroupHeader}>
        <CalendarIcon />
        <h3>Date</h3>
      </div>
      <FormRow className={styles.row}>
        <FormCol>
          <DatePicker
            startDate={startDate}
            startDateId="start_date"
            endDate={endDate}
            minimumNights={0}
            endDateId="end_date"
            onDatesChange={({ startDate, endDate }) =>
              setDates([startDate, endDate])
            }
            readOnly={true}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
          />
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Start time">
            <select
              name="start_time"
              required
              value={start_time}
              onChange={onChange}
            >
              <option value="" disabled="">
                {' '}
                Select time
              </option>
              {data.TIME.map(type => {
                return (
                  <option value={type} key={type}>
                    {type}
                  </option>
                )
              })}
            </select>
          </FormInput>
        </FormCol>
        <FormCol>
          <FormInput label="End time">
            <select
              name="end_time"
              required
              value={end_time}
              onChange={onChange}
            >
              <option value="" disabled="">
                {' '}
                Select time
              </option>
              {data.TIME.map(type => {
                return (
                  <option value={type} key={type}>
                    {type}
                  </option>
                )
              })}
            </select>
          </FormInput>
        </FormCol>
      </FormRow>
      <NextButton onClickHandler={() => onClickHandler('location')} />
    </IntersectionScroll>
  )
}
