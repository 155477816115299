import React from 'react'

import styles from './QuoteStep.module.scss'
import { FormInput, FormRow, FormCol } from 'components/shared/Forms'
import { PhoneIcon } from 'components/shared/Icons'
import IntersectionScroll from 'components/shared/IntersectionScroll'

export default ({
  initialData,
  updateCurrentSection,
  updateRefMap,
  onChange,
}) => {
  let { fname, lname, phone, email } = initialData

  return (
    <IntersectionScroll
      id="contact"
      observeOnScroll
      updateCurrentSection={updateCurrentSection}
      updateRefMap={updateRefMap}
      className={styles.questionGroup}
    >
      <div className={styles.questionGroupHeader}>
        <PhoneIcon />
        <h3>Contact Information</h3>
      </div>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="First Name">
            <input
              type="fname"
              name="fname"
              required
              onChange={onChange}
              maxLength="250"
            />
          </FormInput>
        </FormCol>
        <FormCol>
          <FormInput label="Last Name">
            <input
              type="lname"
              name="lname"
              required
              onChange={onChange}
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Phone">
            <input
              type="phone"
              name="phone"
              required
              onChange={onChange}
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Email">
            <input
              type="email"
              name="email"
              required
              onChange={onChange}
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
    </IntersectionScroll>
  )
}
