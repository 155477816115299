import React from 'react'

import styles from './QuoteStep.module.scss'
import { FormInput, FormRow, FormCol } from 'components/shared/Forms'
import { MapIcon } from 'components/shared/Icons'
import IntersectionScroll from 'components/shared/IntersectionScroll'
import NextButton from './NextButton'

export default ({
  initialData,
  updateCurrentSection,
  updateRefMap,
  onChange,
  onClickHandler,
}) => {
  let { address1, address2, city, zipcode } = initialData
  return (
    <IntersectionScroll
      id="location"
      observeOnScroll
      updateCurrentSection={updateCurrentSection}
      updateRefMap={updateRefMap}
      className={styles.questionGroup}
    >
      <div className={styles.questionGroupHeader}>
        <MapIcon />
        <h3>Location of Event</h3>
      </div>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Address Line 1">
            <input
              type="text"
              name="address1"
              value={address1}
              onChange={onChange}
              required
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Address Line 2">
            <input
              type="text"
              name="address2"
              value={address2}
              onChange={onChange}
              required
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="City">
            <input
              type="text"
              name="city"
              value={city}
              required
              onChange={onChange}
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <FormRow className={styles.row}>
        <FormCol>
          <FormInput label="Zipcode">
            <input
              type="text"
              name="zipcode"
              value={zipcode}
              required
              onChange={onChange}
              maxLength="250"
            />
          </FormInput>
        </FormCol>
      </FormRow>
      <NextButton onClickHandler={() => onClickHandler('contact')} />
    </IntersectionScroll>
  )
}
