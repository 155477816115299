import React from 'react'
import { Link } from 'gatsby'

import styles from './SignUp.module.scss'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import {
  Alert,
  FormInput,
  FormContainer,
  FormRow,
  FormCol,
} from 'components/shared/Forms'
import Switcher from 'components/shared/Switcher'

const INITIAL_STATE = {
  fname: '',
  lname: '',
  type: 'individual',
  email: '',
  phone: '',
  password: '',
  error: null,
}

const TYPES = [
  {
    content: 'Individual',
    value: 'individual',
  },
  {
    content: 'Organization',
    value: 'org',
  },
]

export const LegalAgreement = () => (
  <p className={styles.legal}>
    By continuing, you agree to Borrowed's{' '}
    <a target="_blank" href="/legal/terms">
      Terms of Service
    </a>{' '}
    and{' '}
    <a target="_blank" href="/legal/privacy">
      Privacy Policy
    </a>
    .
  </p>
)

export default class SignUpForm extends React.Component {
  state = {
    ...INITIAL_STATE,
  }

  onSubmit = event => {
    event.preventDefault()

    const { fname, lname, email, password } = this.state
    ;(async () => {
      const error = await this.props.onSubmit(email, password, fname, lname)
      if (error) {
        this.setState({ error })
      }
    })()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { fname, lname, type, phone, email, password, error } = this.state

    const isInvalid = password === '' || fname === '' || email === ''

    return (
      <FormContainer>
        <Card>
          <form onSubmit={this.onSubmit}>
            <FormRow>
              <FormCol style={{ marginBottom: 10 }}>
                <Switcher
                  tabs={TYPES}
                  onSelectTab={tab => this.setState({ type: TYPES[tab].value })}
                />
              </FormCol>
            </FormRow>
            <FormRow>
              {type == 'individual' ? (
                <>
                  <FormCol>
                    <FormInput label="First Name">
                      <input
                        type="text"
                        autoFocus
                        required
                        maxLength="250"
                        name="fname"
                        value={fname}
                        onChange={this.onChange}
                      />
                    </FormInput>
                  </FormCol>
                  <FormCol>
                    <FormInput label="Last Name">
                      <input
                        type="text"
                        required
                        name="lname"
                        value={lname}
                        onChange={this.onChange}
                      />
                    </FormInput>
                  </FormCol>
                </>
              ) : (
                <FormCol>
                  <FormInput label="Company Name">
                    <input
                      type="text"
                      autoFocus
                      required
                      maxLength="250"
                      name="fname"
                      value={fname}
                      onChange={this.onChange}
                    />
                  </FormInput>
                </FormCol>
              )}
            </FormRow>
            {type == 'org' && (
              <FormRow>
                <FormCol>
                  <FormInput label="Phone Number">
                    <input
                      type="phone"
                      required
                      name="phone"
                      value={phone}
                      onChange={this.onChange}
                    />
                  </FormInput>
                </FormCol>
              </FormRow>
            )}
            <FormRow>
              <FormCol>
                <FormInput label="Email Address">
                  <input
                    type="email"
                    required
                    name="email"
                    value={email}
                    onChange={this.onChange}
                  />
                </FormInput>
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol>
                <FormInput label="Password">
                  <input
                    type="password"
                    required
                    name="password"
                    value={password}
                    onChange={this.onChange}
                  />
                </FormInput>
              </FormCol>
            </FormRow>

            <LegalAgreement />

            {error && (
              <Alert type="error" visible={error} message={error.message} />
            )}

            <Button big full disabled={isInvalid}>
              Sign Up
            </Button>
          </form>
        </Card>
        <div className={styles.secondaryAction}>
          <div className={styles.prompt}>Already have an account? </div>
          <Link to="/users/login">Sign In</Link>
        </div>
      </FormContainer>
    )
  }
}
